<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pt-10">
    <v-form @submit.prevent="">
      <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-5 mt-16"
        id="login"
      >
        <v-flex xs12 class="">
          <p>Apice Virtual</p>
          <p class="headline">Super Partner Login</p>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="Username"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="adminLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="adminLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    adminLoginLoading: false,
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        this.adminLoginLoading = true;

        try {
          var adminLoginResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `{
                superPartnerLogin(user_name:"${this.username}",password:"${this.password}"){
                     admin_id
                     superPartner
                   }
              }
              `,
            },
          });

          if (adminLoginResult.data.data.superPartnerLogin.length > 0) {
            this.$store.dispatch(
              "loginAdmin",
              adminLoginResult.data.data.superPartnerLogin[0]
            );

            this.$store.dispatch("sessionTime", Date.now());
            this.$router.push({ name: "home" });
          } else alert("Please enter correct username and password");
        } catch (err) {
          alert(err);
        }

        this.adminLoginLoading = false;
      } else alert("Please fill all the required fields");
    },
  },
  created() {
    // if (this.$store.state.admin != "") this.$router.push({ name: "home" });
    // this.$store.dispatch("loginCashier", false);
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}
</style>
