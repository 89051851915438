<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <div>
        <div v-if="$store.state.whichToShow == 'keno'">
          <p class="headline white--text ml-5">
            Keno Jackpot
            <v-menu rounded="lg">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  small
                  text
                  class="pl-4 pr-4 text-capitalize white mr-3"
                  v-bind="attrs"
                  v-on="on"
                  outlined=""
                >
                  {{ $store.state.whichToShow }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item
                    v-for="item in gameItems"
                    :key="item"
                    @click="$store.dispatch('wichToSwitch', item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </p>
          <div v-if="$store.state.admin != ''" class="" style="font-size: 13px">
            <div class="text-center" v-if="pageLoading">
              <v-progress-circular
                :size="70"
                :width="2"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </div>

            <div
              v-if="!pageLoading"
              class="white pa-10 pl-15 pr-15 pt-5"
              id="report"
              style="border-radius: 3px"
            >
              <v-form @submit.prevent="" id="">
                <v-layout
                  row
                  wrap
                  class="mb-6 pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <p class="text-center headline">Jackpot</p>
                  <v-flex xs12>
                    <v-switch
                      @click="editJackpot"
                      class="mr-5"
                      style="float: right; clear: right"
                      v-model="kenoJackpot.on_off"
                      label="ON/OFF"
                    ></v-switch>
                  </v-flex>
                  <v-flex xs12 class="pt-1">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot %"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot amount"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Game no (random select)"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.reward_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Available reward reched"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 class="pt-1 text-center">
                    <p class="text-center">Edit Jackpot</p>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.edit_percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot %"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.edit_min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot amount"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.edit_game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Game no (How many game #s randomly selected)"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.edit_jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="editJackpot"
                      :loading="editjackpotLoading"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <br />
                <v-layout
                  row
                  wrap
                  class="pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <p class="text-center headline">Super Jackpot</p>
                  <v-flex xs12>
                    <v-switch
                      @click="editJackpot"
                      class="mr-5"
                      style="float: right; clear: right"
                      v-model="kenoJackpot.s_on_off"
                      label="Super ON/OFF"
                    ></v-switch>
                  </v-flex>
                  <v-flex xs12 class="pt-1">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot %"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot amount"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Game no (random select)"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_reward_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super jackpot reward reched"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 class="pt-1 text-center">
                    <p class="text-center">Edit Super Jackpot</p>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_edit_percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot %"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_edit_min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot amount"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_edit_game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Game no (How many game #s randomly selected)"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <v-text-field
                      style="font-size: 11px"
                      v-model="kenoJackpot.s_edit_jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="editJackpot"
                      :loading="editjackpotLoading"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
          </div>

          <div v-else id="error" class="text-center mt-16">
            <p class="red--text text-center">Please login first</p>
            <a @click="$router.push({ name: 'login' })">login</a>
          </div>
        </div>

        <div v-if="$store.state.whichToShow == 'spin'">
          <p class="headline white--text ml-5">
            Spin Jackpot
            <v-menu rounded="lg">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  small
                  text
                  class="pl-4 pr-4 text-capitalize white mr-3"
                  v-bind="attrs"
                  v-on="on"
                  outlined=""
                >
                  {{ $store.state.whichToShow }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item
                    v-for="item in gameItems"
                    :key="item"
                    @click="$store.dispatch('wichToSwitch', item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </p>
          <div v-if="$store.state.admin != ''" class="" style="font-size: 13px">
            <div class="text-center" v-if="pageLoading">
              <v-progress-circular
                :size="70"
                :width="2"
                color="grey"
                indeterminate
              ></v-progress-circular>
            </div>

            <div
              v-if="!pageLoading"
              class="white pa-10 pl-15 pr-15 pt-5"
              id="report"
              style="border-radius: 3px"
            >
              <v-form @submit.prevent="" id="">
                <v-layout
                  row
                  wrap
                  class="mb-6 pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <p class="text-center headline">Jackpot</p>
                  <v-flex xs12>
                    <v-switch
                      @click="spineditJackpot"
                      class="mr-5"
                      style="float: right; clear: right"
                      v-model="spinJackpot.on_off"
                      label="ON/OFF"
                    ></v-switch>
                  </v-flex>
                  <v-flex xs12 class="pt-1">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot %"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot amount"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Game no (random select)"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.reward_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Available reward reched"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 class="pt-1 text-center">
                    <p class="text-center">Edit Jackpot</p>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.edit_percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot %"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.edit_min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jackpot amount"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.edit_game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Game no (How many game #s randomly selected)"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.edit_jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="spineditJackpot"
                      :loading="editjackpotLoading"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <br />
                <v-layout
                  row
                  wrap
                  class="pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <p class="text-center headline">Super Jackpot</p>
                  <v-flex xs12>
                    <v-switch
                      @click="spineditJackpot"
                      class="mr-5"
                      style="float: right; clear: right"
                      v-model="spinJackpot.s_on_off"
                      label="Super ON/OFF"
                    ></v-switch>
                  </v-flex>
                  <v-flex xs12 class="pt-1">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot %"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot amount"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Game no (random select)"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_reward_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super jackpot reward reched"
                      required
                      dense
                      outlined
                      disabled
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 class="pt-1 text-center">
                    <p class="text-center">Edit Super Jackpot</p>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_edit_percent"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot %"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_edit_min_amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Jackpot amount"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_edit_game_no"
                      :rules="[(v) => !!v || 'Required']"
                      label="Super Game no (How many game #s randomly selected)"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinJackpot.s_edit_jump_number"
                      :rules="[(v) => !!v || 'Required']"
                      label="Jump Number"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="spineditJackpot"
                      :loading="editjackpotLoading"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
          </div>

          <div v-else id="error" class="text-center mt-16">
            <p class="red--text text-center">Please login first</p>
            <a @click="$router.push({ name: 'login' })">login</a>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,

      editjackpotLoading: false,
      gameItems: ["keno", "spin"],

      helpText: "",
      kenoJackpot: "",
      spinJackpot: "",
      group: null,
    };
  },

  methods: {
    async getJackpotRule() {
      this.pageLoading = true;
      try {
        var kenoJakpotResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                getJackpotRule{
   jackpot_id
    percent
    min_amount
    game_no
    on_off
    reward_amount
    
    edit_percent
    edit_min_amount
    edit_game_no
    
    s_percent
    s_min_amount
    s_game_no
    s_on_off
    s_reward_amount
    
    s_edit_percent
    s_edit_min_amount
    s_edit_game_no   

    jump_number
    s_jump_number

    s_edit_jump_number
    edit_jump_number
  }
                
                }
                `,
          },
        });

        this.kenoJackpot = kenoJakpotResult.data.data.getJackpotRule[0];
      } catch (err) {
        alert(err);
      }

      try {
        var spinJakpotResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
                getJackpotRule{
   jackpot_id
    percent
    min_amount
    game_no
    on_off
    reward_amount
    
    edit_percent
    edit_min_amount
    edit_game_no
    
    s_percent
    s_min_amount
    s_game_no
    s_on_off
    s_reward_amount
    
    s_edit_percent
    s_edit_min_amount
    s_edit_game_no   
    jump_number
    s_jump_number

    s_edit_jump_number
    edit_jump_number
  }
                
                }
                `,
          },
        });

        this.spinJackpot = spinJakpotResult.data.data.getJackpotRule[0];
      } catch (err) {}

      this.pageLoading = false;
    },

    async editJackpot() {
      this.editjackpotLoading = true;

      try {
        var jackeditResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                     editJackpot(
                        edit_percent: ${
                          this.kenoJackpot.edit_percent
                        }, edit_min_amount: ${this.kenoJackpot.edit_min_amount},
    edit_game_no: ${this.kenoJackpot.edit_game_no},
    s_edit_jump_number:${this.kenoJackpot.s_edit_jump_number},
   edit_jump_number:${this.kenoJackpot.edit_jump_number},
    on_off: ${this.kenoJackpot.on_off == true ? 1 : 0},
    s_edit_percent: ${this.kenoJackpot.s_edit_percent}, s_edit_min_amount: ${
              this.kenoJackpot.s_edit_min_amount
            },
    s_edit_game_no: ${this.kenoJackpot.s_edit_game_no},
    s_on_off: ${this.kenoJackpot.s_on_off == true ? 1 : 0},
    
                            ){
                              statusMessage
                          }
  
                }
                `,
          },
        });

        var result = jackeditResult.data.data.editJackpot[0].statusMessage;
        if (result.toString() == "true") {
          alert("Changed successfully!");
          this.getJackpotRule();
        } else if (result.toString() == "false") {
          alert("Some thing went wrong, try again");
        } else alert(result);
      } catch (err) {
        alert(err);
      }

      this.editjackpotLoading = false;
    },

    async spineditJackpot() {
      this.editjackpotLoading = true;

      try {
        var jackeditResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `mutation{
                     editJackpot(
                        edit_percent: ${
                          this.spinJackpot.edit_percent
                        }, edit_min_amount: ${this.spinJackpot.edit_min_amount},
    edit_game_no: ${this.spinJackpot.edit_game_no},
    s_edit_jump_number:${this.spinJackpot.s_edit_jump_number},
   edit_jump_number:${this.spinJackpot.edit_jump_number},
    on_off: ${this.spinJackpot.on_off == true ? 1 : 0},
    s_edit_percent: ${this.spinJackpot.s_edit_percent}, s_edit_min_amount: ${
              this.spinJackpot.s_edit_min_amount
            },
    s_edit_game_no: ${this.spinJackpot.s_edit_game_no},
    s_on_off: ${this.spinJackpot.s_on_off == true ? 1 : 0},
    
                            ){
                              statusMessage
                          }
  
                }
                `,
          },
        });

        var result = jackeditResult.data.data.editJackpot[0].statusMessage;
        if (result.toString() == "true") {
          alert("Changed successfully!");
          this.getJackpotRule();
        } else if (result.toString() == "false") {
          alert("Some thing went wrong, try again");
        } else alert(result);
      } catch (err) {
        alert(err);
      }

      this.editjackpotLoading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 8 * 60 * 1000) {
        this.$store.dispatch("loginAdmin", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      await this.getJackpotRule();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 600px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

#video {
  border-radius: 7px;
  border: none;
}
</style>

<!-- if(jackrow[0].fromwhich_gameno==0){
                               var jackUpdateSql = `UPDATE jackpot SET
                                   fromwhich_gameno = ${parseInt(row[0].game_number)+jackrow[0].jump_number}`;
                             
                             conn.query(
                             jackUpdateSql,
                             function (err, jackUpdate, field) {}
                             );


                             }else{
                             if(jackrow[0].fromwhich_gameno<parseInt(row[0].game_number)){
                                
                             }
                             } -->
