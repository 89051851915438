<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Settings</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div>
          <div
            v-if="!pageLoading"
            class="white pt-4 pb-10 pa-2"
            id="percent"
            style="border-radius: 3px"
          >
            <v-layout row wrap id="customPercent">
              <v-flex xs12 class="">
                <v-switch
                  v-if="isAllowed == 1"
                  @click="changeAllow"
                  :loading="allowLoading"
                  class=""
                  style="float: right; clear: right"
                  v-model="isReleased"
                  label="Control"
                ></v-switch>
                <v-switch
                  v-else
                  disabled
                  class=""
                  style="float: right; clear: right"
                  v-model="isReleased"
                  label="Control"
                ></v-switch>
                <v-menu rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      :loading="percentLoading"
                      text
                      class="pl-10 pr-10 mt-4 text-capitalize mr-3"
                      v-bind="attrs"
                      v-on="on"
                      outlined=""
                    >
                      {{ choosedGame }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="group"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <v-list-item
                        v-for="item in items"
                        :key="item"
                        @click="choosedGame = item"
                      >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-flex>
              <v-flex xs12 v-if="isAllowed == 1">
                <v-layout
                  row
                  wrap
                  class="text-center mt-2 ma-1"
                  v-if="isReleased"
                >
                  <v-flex
                    xs4
                    md3
                    v-for="number in customPercent"
                    :key="number.supper_partner_percent_id"
                    class="mt-0 mb-1"
                  >
                    <v-card
                      class="mr-1 pink lighten-3"
                      id="numbers"
                      v-if="
                        currentPercentField == number.percent_value ||
                        spinCurrentPercentField == number.percent_value
                      "
                    >
                      <v-card-text>
                        <div>
                          <a @click="savePercent(number.percent_value)">
                            <div class="pt-1">
                              <span class="pa-1">{{ number.Level }}</span>
                            </div>
                          </a>
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-card class="mr-1" id="numbers" v-else>
                      <v-card-text>
                        <div>
                          <a @click="savePercent(number.percent_value)">
                            <div class="pt-1">
                              <span class="pa-1">{{ number.Level }}</span>
                            </div>
                          </a>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>

                <p v-else class="text-center red--text headline">
                  Game settings will be controlled by the Adminstrator.
                </p>
              </v-flex>
              <v-flex xs12 v-else class="text-center">
                <p class="text-center red--text headline">
                  Access Denied! please contact the Admin
                </p>
              </v-flex>
            </v-layout>
          </div>
        </div>
        <!-- <div v-else class="mt-16">
          <p v-if="!pageLoading" class="headline red--text text-center">
            Reachs maximum day, Please contact the admin
          </p>
        </div> -->
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please Login</p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,

      currentPercentField: "",
      spinCurrentPercentField: "",
      percentLoading: false,
      clearLoading: false,
      commonFeatures: [],
      constantDialog: false,
      constantLoading: false,
      brandName: "",
      phoneNumber: "",
      others: "",
      customPercent: [],
      items: ["All", "Keno", "Spin"],
      choosedGame: "All",
      group: null,
      isAllowed: "",
      isReleased: "",
      allowLoading: false,
    };
  },

  methods: {
    async savePercent(percent) {
      if (percent != "") {
        this.percentLoading = true;
        var changePercentResult = "";
        try {
          if (this.choosedGame == "Keno") {
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.adminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
          } else if (this.choosedGame == "Spin") {
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
          } else if (this.choosedGame == "All") {
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.adminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
          }

          if (changePercentResult != "") {
            if (
              changePercentResult.data.data.changePercent[0].statusMessage.toString() !=
              "true"
            ) {
              alert("No change, please try again");
            } else await this.getCurrentPercent();
          }
        } catch (err) {
          alert(err);
        }
        this.percentLoading = false;
      } else alert("Please fill the field");
    },
    async getCurrentPercent() {
      this.pageLoading = true;
      try {
        var currentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                 getPercent{
                 percent_id
                 percent_value
                 }
                }
                `,
          },
        });

        this.currentPercentField =
          currentPercentResult.data.data.getPercent[0].percent_value;
      } catch (err) {
        alert(err);
      }

      try {
        var spinCurrentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
                 getPercent{
                 percent_id
                 percent_value
                 }
                }
                `,
          },
        });

        this.spinCurrentPercentField =
          spinCurrentPercentResult.data.data.getPercent[0].percent_value;
      } catch (err) {}

      try {
        var supperLevelsResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                 getSupperPartnerLevels{
                   supper_partner_percent_id
                   Level
                   percent_value
                 }
                }
                `,
          },
        });

        this.customPercent =
          supperLevelsResult.data.data.getSupperPartnerLevels;
      } catch (err) {}

      try {
        var partnerResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getPartner {
                is_allowed
                is_released
               }
              }
              `,
          },
        });
        this.isAllowed = partnerResult.data.data.getPartner[0].is_allowed;
        this.isReleased = partnerResult.data.data.getPartner[0].is_released;
      } catch (err) {}

      this.loadingCount++;
      this.pageLoading = false;
    },

    async changeAllow() {
      if (this.isReleased) this.isReleased = 1;
      else this.isReleased = 0;
      this.allowLoading = true;
      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeIsRelease(is_released:${this.isReleased}){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeIsRelease[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.allowLoading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 8 * 60 * 1000) {
        this.$store.dispatch("loginAdmin", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      await this.getCurrentPercent();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
