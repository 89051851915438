<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="">
      <p class="headline white--text ml-5">List Of Games</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pt-4 pb-10 pa-2 pl-3 pr-5"
          id="percent"
          style="border-radius: 3px"
        >
          <v-layout
            row
            wrap
            id="customPercent"
            v-for="(games, i) in listOfGames"
            :key="i"
            class="pr-3"
          >
            <v-flex xs12>
              <br />
            </v-flex>
            <v-flex xs7 class="pr-1 mt-5">
              <strong>
                <span>{{ games.game_name }}</span></strong
              >
              <br />
              <v-layout
                row
                wrap
                v-for="(color, i) in games.getBackgroundColors"
                :key="i"
                class="pl-2 mt-2"
              >
                <v-flex xs6 class="pr-2 mt-5">
                  <span>{{ color.background_name }}</span>
                </v-flex>

                <v-flex xs5>
                  <v-switch
                    @click="allowColor(color)"
                    class="pa-2"
                    v-model="color.is_allowed"
                    label="Allow"
                  ></v-switch>
                </v-flex>

                <v-flex xs1>
                  <v-switch
                    @click="allowColor(color)"
                    class="pa-2"
                    v-model="color.is_visible"
                    label="Hide"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 class="">
              <v-switch
                @click="allowGame(games)"
                class="mr-5"
                v-model="games.is_allowed"
                label="Allow"
              ></v-switch>
            </v-flex>
            <v-flex xs2 class="pr-3">
              <v-switch
                @click="allowGame(games)"
                class="mr-5"
                v-model="games.is_visible"
                label="Hide"
              ></v-switch>
            </v-flex>
            <v-flex xs12 class="pt-3 pb-3">
              <v-divider> </v-divider>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      listOfGames: [],
      allowLoading: false,
      isAllowed: "",
    };
  },

  methods: {
    // async saveConstants() {
    //   this.constantLoading = true;
    //   try {
    //     var changeConstatnsResult = await axios({
    //       method: "POST",
    //       url: this.$store.state.adminURL,

    //       data: {
    //         query: `mutation{
    //              changeConstants(
    //               brand_name:"${this.brandName}",
    //               phone:"${this.phoneNumber}",
    //               others:"${this.others}",
    //               ){
    //              statusMessage

    //              }
    //             }
    //             `,
    //       },
    //     });

    //     if (
    //       changeConstatnsResult.data.data.changeConstants[0].statusMessage.toString() ==
    //       "true"
    //     ) {
    //       this.constantDialog = false;
    //       this.getCurrentPercent();
    //     } else alert("No change, please try again");
    //   } catch (err) {
    //     alert(err);
    //   }
    //   this.constantLoading = false;
    // },
    async getListOfGames() {
      this.pageLoading = true;
      try {
        var gamesResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                getGameList{
                    list_games_id
                    game_name
                    is_allowed
                    is_visible
                    getBackgroundColors{
                      game_background_id
                      list_games_id
                      background_name
                      is_allowed
                      is_visible
                    }
                  }
                 
                  }
                  `,
          },
        });

        this.listOfGames = gamesResult.data.data.getGameList;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },

    async allowGame(games) {
      if (games.is_allowed) games.is_allowed = 1;
      else games.is_allowed = 0;

      if (games.is_visible) games.is_visible = 1;
      else games.is_visible = 0;
      try {
        var allowGameResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                     allowGame(
                        list_games_id:${games.list_games_id},
                        is_allowed:${games.is_allowed},
                        is_visible:${games.is_visible},
                         ){
                         statusMessage
                      }
                
                }
                `,
          },
        });

        if (
          allowGameResult.data.data.allowGame[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }
    },

    async allowColor(color) {
      if (color.is_visible) color.is_visible = 1;
      else color.is_visible = 0;

      if (color.is_allowed) color.is_allowed = 1;
      else color.is_allowed = 0;
      try {
        var allowColorResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                     allowColor(
                        game_background_id:${color.game_background_id},
                        is_allowed:${color.is_allowed},
                        is_visible:${color.is_visible},
                         ){
                         statusMessage
                      }
                
                }
                `,
          },
        });

        if (
          allowColorResult.data.data.allowColor[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 8 * 60 * 1000) {
        this.$store.dispatch("loginAdmin", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      await this.getListOfGames();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
