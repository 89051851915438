import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";

import moment from "moment";

import VueHtmlToPaper from "vue-html-to-paper";

// Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    firebase.initializeApp({
      apiKey: "AIzaSyCjP-94w5a7MAOLfSwnHgkE7c-j0YlFSPI",
      authDomain: "jodan-c3ddb.firebaseapp.com",
      projectId: "jodan-c3ddb",
      storageBucket: "jodan-c3ddb.appspot.com",
      messagingSenderId: "368424568525",
      appId: "1:368424568525:web:bf3925266954eb9c840b84",
      measurementId: "G-ME2Y8HQ206",
    });
  },
}).$mount("#app");
